.DownButton {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    transform: translateX(-50%);
    border: none;
    background: unset;
    font-size: 30px;
    font-weight: bold;

    @media (min-width: 1024px) {
        top: 90vh;
        bottom: unset;
        right: unset;
        left: 70vw;
    }

    cursor: pointer;

    svg path {
        fill: #FFF;
        @media (min-width: 1024px) {
            fill: rgb(var(--blue-extra-dark));
        }
    }

    &.hide {
        display: none;
    }
}
