@import 'assets/styles/base';
@import 'assets/styles/variables';
.App {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  background-color: rgb(var(--blue-light));
  .Header {
    width: 100vw;
    background-color: rgb(var(--blue-extra-dark));
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    a {
      text-decoration: none;
    }
    &__Name {
      font-size: 36px;
      cursor: pointer;
    }
    &__Title {
      font-size: 24px;
      font-weight: lighter;
      cursor: pointer;
    }
  }
  .content__wrapper {
    max-width: 600px;
    width: 90%;
    margin: 0 auto;
    h2 {
      margin-bottom: 1rem;
      font-size: 24px;
      &:first-child {
        margin-top: 2rem;
      }
    }
  }
}
