.Footer {
    width: 100vw;
    position: absolute;
    bottom: 0;
    padding: 0;
    background-color: rgb(var(--blue-light));
    nav ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        li {
            list-style-type: none;
            padding: 1rem;
            a {
                color: rgb(var(--blue-dark));
                text-decoration: none;
                transition: all ease 0.5s;
                &:hover {

                }
            }
        }
    }
}