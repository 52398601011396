.Services {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    @media (min-width: 1440px) {
        flex-direction: row;
    }

    h2 {
        font-size: 30px;
        @media (min-width: 1440px) {
            min-height: 15vh;
            font-size: 60px;
        }
    }

    &__Portfolio {
        background-color: rgb(var(--blue-dark));
        padding: var(--padding-mobile);
        @media (min-width: 1024px) {
            padding: var(--padding-desktop);
        }
    }

    &__Difference {
        background-color: rgb(var(--blue-middle));
        padding: var(--padding-mobile);
        @media (min-width: 1024px) {
            padding: var(--padding-desktop);
        }
    }

    &__Core {
        background-color: rgb(var(--blue-light));
        padding: var(--padding-mobile);
        @media (min-width: 1024px) {
            padding: var(--padding-desktop);
        }
    }

    &>div {
        width: calc(100% - 2rem);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        position: relative;
        padding-bottom: 2rem;
        @media (min-width: 1024px) {
            width: calc(100% - 8rem);
        }
        @media (min-width: 1440px) {
            width: 33%;
        }

        .details {
            opacity: 1;
            visibility: visible;
            transition: all ease-in-out 0.5s;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
        }
    }

    .info {
        position: relative;
        opacity: 0;
        visibility: hidden;
        width: 1px;
        height: 1px;
        &.fadeIn { 
            height: 350px;
            width: 350px;
            opacity: 1;
            visibility: visible;    
            transition: all ease 1s;  
            &.info--2 {           

                transition-delay: 1s;
            }
            &.info--3 {

                transition-delay: 2s;
            }
        }

        &--core {
            margin-bottom: 4rem;
            align-self: center;
        }

        &__main {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: rgb(var(--text-color));
            border-radius: 50%;
            padding: 1rem;
            height: 175px;
            width: 175px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 18px;
            color: rgb(var(--blue-extra-dark));
            font-weight: bold;
        }

        &__item {
            position: absolute;
            border-radius: 50%;
            padding: 1rem;
            height: 75px;
            width: 75px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            transform: translateX(-50%);

            &.info__item--portfolio {
                flex-direction: column;

                ul {
                    margin: 0.5rem 1rem;
                }

                li {
                    font-size: 10px;
                    text-align: left;
                    line-height: 12px;
                }
            }

            &--1 {
                top: 0;
                left: 50%;
                background-color: rgba(var(--blue-extra-dark), 0.8);

                &.info__item--portfolio {
                    background-color: rgba(var(--blue-middle), 0.8);
                }
            }

            &--2 {
                top: 30%;
                right: 0;
                transform: translate(0, -50%);
                background-color: rgba(var(--blue-light), 0.8);

                &.info__item--core {
                    background-color: rgba(var(--blue-middle), 0.8);
                }

                &.info__item--portfolio {
                    left: unset;
                    right: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                }
            }

            &--3 {
                top: 70%;
                right: 0;
                transform: translate(0, -50%);
                background-color: rgba(var(--blue-dark), 0.8);

                &.info__item--portfolio {
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: rgba(var(--blue-middle), 0.8);
                }
            }

            &--4 {
                bottom: 0;
                left: 50%;
                background-color: rgba(var(--blue-extra-dark), 0.8);

                &.info__item--portfolio {
                    left: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    background-color: rgba(var(--blue-light), 0.8);
                }
            }

            &--5 {
                top: 70%;
                left: 0;
                transform: translate(0, -50%);
                background-color: rgba(var(--blue-light), 0.8);

                &.info__item--core {
                    background-color: rgba(var(--blue-middle), 0.8);
                }
            }

            &--6 {
                top: 30%;
                left: 0;
                transform: translate(0, -50%);
                background-color: rgba(var(--blue-dark), 0.8);
            }
        }
    }
}
