.Projects {
    background-color: rgb(var(--blue-light));
    padding: var(--padding-mobile);
    padding-bottom: 8rem;

    @media (min-width: 1024px) {
        padding: var(--padding-desktop);
        padding-bottom: 8rem;
    }

    h2 {
        margin: 0 0 4rem 0;
        font-size: 24px;
        color: rgb(var(--blue-extra-dark));

        @media (min-width: 1024px) {
            font-size: 40px;
        }
    }

    h3 {
        color: rgb(var(--blue-extra-dark));
        margin-bottom: 2rem;
    }

    p {
        color: rgb(var(--blue-extra-dark));
    }

    &__Wrapper {
        display: flex;
        flex-direction: column;

        @media (min-width: 1200px) {
            flex-direction: row;
        }

        &>div {
            width: 100%;
            flex: 1;

            @media (min-width: 1200px) {
                width: 33%;
            }
        }

        .item {
            margin-right: 4rem;
            margin-bottom: 4rem;
            opacity: 0;
            visibility: hidden;

            @media (min-width: 1200px) {
                margin-bottom: unset;
            }

            &.fadeIn {
                opacity: 1;
                visibility: visible;
                transition: all ease 1s;

                &.item-2 {

                    transition-delay: 1s;
                }

                &.item-3 {

                    transition-delay: 2s;
                }
            }
        }

        .item-3 {
            margin-right: 0;
        }
    }
}

@keyframes fadeInProject {
    from {
        opacity: 0;
        visibility: hidden;
    }

    to {
        opacity: 1;
        visibility: visible;
    }
}