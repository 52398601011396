.Intro {
    min-height: 100vh;
    min-height: var(--app-height);
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: rgb(var(--blue-extra-dark));

    @media (min-width: 1024px) {
        flex-direction: row;
        background-color: #FFF;
    }

    &__Left {
        height: 40vh;
        overflow: hidden;

        @media (min-width: 1024px) {
            flex-direction: row;
            flex: 0 40%;
            height: 100vh;
        }
    }

    &__Right {
        flex: 0 60%;
        position: relative;
        @media (max-width: 1023px) {
        }
    }

    &__Image {
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }

    &__Glasses {
        height: 10vh;
        width: auto;
        display: block;
        margin: 7vh auto;
        mix-blend-mode: multiply;
        @media (min-width: 1024px) {
            mix-blend-mode: normal;
        }

        @media (min-width: 500px) {
            height: 15vh;
            margin: 6vh auto;
        }

        @media (min-width: 1024px) {
            height: 20vh;
            margin: 10vh auto;
        }
    }

    h1,
    h2,
    .claim {
        color: #FFF;
        text-align: center;
        @media (min-width: 1024px) {
            color: rgb(var(--blue-dark));
        }
    }

    h1 {
        font-size: 40px;
        line-height: 40px;
        margin-top: var(--padding-mobile);
        // margin-left: var(--padding-mobile);

        @media (min-width: 1024px) {
            font-size: 70px;
            line-height: 70px;
            margin-top: var(--padding-desktop);
            margin-left: 5rem;
            text-align: left;
        }

        @media (min-width: 1440px) {
            font-size: 100px;
            line-height: 100px;
            margin-top: var(--padding-desktop);
            margin-left: 8rem;
        }
    }

    h2 {
        font-weight: lighter;
        font-size: 20px;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        //margin-left: 1.15rem;

        @media (min-width: 1024px) {
            font-size: 40px;
            margin-top: 1rem;
            margin-bottom: 1rem;
            margin-left: 5.15rem;
            text-align: left;
        }

        @media (min-width: 1440px) {
            margin-left: 8.15rem;
        }
    }

    .claim {
        text-align: center;
        display: block;
        position: absolute;
        right: 50%;
        bottom: -3rem;
        transform: translateX(50%);
        font-size: 24px;
        line-height: 24px;
        font-weight: bold;
        margin-left: 0;

        @media (min-width: 500px) {
            font-size: 30px;
            line-height: 30px;
            bottom: -5rem;
            margin-left: var(--padding-desktop);
        }

        @media (min-width: 1024px) {
            font-size: 40px;
            line-height: 40px;
            bottom: 8rem;
        }
    }
}