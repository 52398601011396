.Profile {
    background-color: rgb(var(--blue-extra-dark));
    display: flex;
    flex-direction: column;
    @media (min-width: 1024px) {
        flex-direction: row;
    }
    & > div {
        flex: 0 50%;
        padding: var(--padding-mobile);
        @media (min-width: 1024px) {
            padding: var(--padding-desktop);
        }
    }
}