.BackButton {
    position: fixed;
    top: 20vh;
    right: 1rem;
    transform: translateX(-50%);
    border: none;
    background: unset;
    font-size: 30px;
    font-weight: bold;
    svg path {
        fill: rgb(var(--blue-extra-dark));
    }

    cursor: pointer;
}
