@font-face {
    font-family: "Roboto Light";
    src: url("../fonts/Roboto-Light.ttf") format("ttf");
  }

  @font-face {
    font-family: "Roboto Bold";
    src: url("../fonts/Roboto-Bold.ttf") format("ttf");
  }

* {
    margin: 0;
    padding: 0;
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    outline: none;
}

a, p, li {
    font-size: 18px;
    line-height: 24px;
}

p {
    margin-bottom: 2rem;
    text-align: justify;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 36px;
    margin-bottom: 4rem;
}