
:root {
    --blue-extra-dark: 46, 69, 89;
    --blue-dark: 20, 78, 115;
    --blue-middle: 31, 141, 166;
    --blue-light: 143, 200, 217;
    --text-color: 255, 255, 255;

    --padding-desktop: 4rem;
    --padding-mobile: 1rem;
    --padding-tablet: 2rem;

    --app-height: 100%;
  }
